import _reactDomProduction from "./cjs/react-dom.production.js";
function checkDCE() {
  /* global __REACT_DEVTOOLS_GLOBAL_HOOK__ */
  if (typeof __REACT_DEVTOOLS_GLOBAL_HOOK__ === "undefined" || typeof __REACT_DEVTOOLS_GLOBAL_HOOK__.checkDCE !== "function") {
    return;
  }
  try {
    // Verify that the code above has been dead code eliminated (DCE'd).
    __REACT_DEVTOOLS_GLOBAL_HOOK__.checkDCE(checkDCE);
  } catch (err) {
    // DevTools shouldn't crash React, no matter what.
    // We should still report in case we break this code.
    console.error(err);
  }
}
if (true) {
  // DCE check should happen before ReactDOM bundle executes so that
  // DevTools can report bad minification during injection.
  checkDCE();
  var exports = _reactDomProduction;
}
export default exports;
export const __DOM_INTERNALS_DO_NOT_USE_OR_WARN_USERS_THEY_CANNOT_UPGRADE = exports.__DOM_INTERNALS_DO_NOT_USE_OR_WARN_USERS_THEY_CANNOT_UPGRADE,
  createPortal = exports.createPortal,
  flushSync = exports.flushSync,
  preconnect = exports.preconnect,
  prefetchDNS = exports.prefetchDNS,
  preinit = exports.preinit,
  preinitModule = exports.preinitModule,
  preload = exports.preload,
  preloadModule = exports.preloadModule,
  requestFormReset = exports.requestFormReset,
  unstable_batchedUpdates = exports.unstable_batchedUpdates,
  useFormState = exports.useFormState,
  useFormStatus = exports.useFormStatus,
  version = exports.version;